import { IPostItAction, IPostIt, IStoredPostIt } from './types'
import ActionNames from './ActionNames'

export const addPostIt = (postIt: IPostIt): IPostItAction => {
  return {
    type: ActionNames.ADD_POSTIT,
    postIt: {
      ...postIt,
      timestamp: Date.now(),
    },
  }
}

export const loadPostIts = (postIts: IStoredPostIt[]): IPostItAction => {
  return {
    type: ActionNames.LOAD_POSTITS,
    postIts,
  }
}

