import React from 'react'
import { StyleSheet, css } from 'aphrodite'
import { Link } from "react-router-dom"

interface IProps {
  link?: {
    to: string,
    text: string
  }
}

const Header: React.FC<IProps> = (props) => {
  return (
    <div className={css(styles.header)} >
      <Link to='/' >
        <img src='mettle-logo.svg' alt='mettle logo' className={css(styles.headerImage)} />
      </Link>
      <div className={css(styles.spacer)} />
      { props.link && <Link className={css(styles.link)} to={props.link.to} >{props.link.text}</Link> }
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    padding: 32,
    flexShrink: 0,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  headerImage: {
    height: 20,
  },

  spacer: {
    flex: 1,
  },

  link: {
  },
});

export default Header
