import React, { useRef, useEffect } from 'react'
import { StyleSheet, css } from 'aphrodite';

import Header from './Header'
import EditablePostIt from './EditablePostIt'
import { useAddPostItHandler, useCircularNavigation } from '../hooks'

const ANIMATION_LENGTH = 1000

const PostItAdder: React.FC = () => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { previousPostIt, postIt, setPostItMessage, setPostItName, canSubmitPostIt, submitPostIt, postItCount } = useAddPostItHandler()
  const circularNavigation = useCircularNavigation()

  const focusInput = () => {
    inputRef.current && inputRef.current.focus()
    return undefined
  }

  useEffect(() => focusInput, [postIt.message, inputRef.current])

  const previousPostItInput = previousPostIt && (
    <div
      key={`previous-${postItCount}`}
      className={css(styles.previousPostItContainer)}
    >
      <div className={css(styles.previousPostItMover, styles.moveUp)}>
        <EditablePostIt
          postIt={previousPostIt}
          readOnly={true}
        />
      </div>
    </div>
  )

  return (
    <div className={css(styles.container)}>
      <Header link={circularNavigation ? { to:'/postits', text:'messages' } : undefined} />
      <form
        className={css(styles.content)}
        onSubmit={(event) => {
          event.preventDefault()
          event.stopPropagation()
          submitPostIt()
          return false
        }}
      >
        {previousPostItInput}
        <label className={`big`}>
          What would your ideal future city look like?
        </label>
        <EditablePostIt
          ref={inputRef}
          key={`current-${postItCount}`}
          className={css(styles.newPostIt, styles.fadeIn)}
          postIt={postIt}
          onNameChange={setPostItName}
          onMessageChange={setPostItMessage}
          onSubmit={submitPostIt}
          readOnly={false}
        />
        <button
          disabled={!canSubmitPostIt}
          className={`alt-font ${css(styles.submit, !canSubmitPostIt && styles.disabled)}`}
          type='submit'>
          Submit
        </button>
      </form>
      <div className={css(styles.footer)} >
        <img src='ldf-logo.png' alt='LDF logo' className={css(styles.footerImage)} />
      </div>
    </div>
  );
}

const fadeInKeyframes = {
  '0%': { opacity: 0 },
  '50%': { opacity: 0 },
  '100%': { opacity: 1 },
};

const movementAmount = window.innerHeight - 255

const moveUpKeyframes = {
  '0%': { top: 0, opacity: 1 },
  '80%': { top: -movementAmount, opacity: 1 },
  '100%': { top: -movementAmount, opacity: 0 },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f2f2f2',
  },

  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },

  footer: {
    padding: 32,
    position: 'absolute',
    bottom: '0',
    left: '0',
  },

  newPostIt: {
    margin: 48,
  },

  previousPostItContainer: {
    position: 'relative',
    pointerEvents: 'none',
  },

  previousPostItMover: {
    left: -310/2 - 30,
    position: 'absolute',
    opacity: 0,
    pointerEvents: 'none',
  },

  fadeIn: {
    animationName: fadeInKeyframes,
    animationDuration: `${ANIMATION_LENGTH}ms`,
  },

  moveUp: {
    animationName: moveUpKeyframes,
    animationDuration: `${ANIMATION_LENGTH}ms`,
  },

  submit: {
    backgroundColor: '#0000',
    borderColor: '#000',
    padding: '8px 24px',
  },

  disabled: {
    borderColor: '#999',
    color: '#999',
    padding: '8px 24px',
  },

  footerImage: {
    height: 120,
  },
});

export default PostItAdder
