import React, { forwardRef, RefObject } from 'react'
import { StyleSheet, css } from 'aphrodite';

import { IPostIt } from '../post-its/types'

interface IProps {
  className?: string
  postIt: IPostIt
  onNameChange?: (name: string) => void
  onMessageChange?: (name: string) => void
  onSubmit?: () => void
  readOnly?: boolean
}

const EditablePostIt: React.FC<IProps> = (props: IProps, ref: RefObject<HTMLTextAreaElement>) => {
  const { className, postIt, onNameChange, onMessageChange, onSubmit, readOnly } = props

  return (
    <div
      className={`${css(styles.postIt)} ${className}`}
      style={{ backgroundColor: postIt.backgroundColor }}
    >
      {!(readOnly && !postIt.name) ? <input
        type='text'
        name="name"
        className={`light ${css(styles.input)}`}
        style={{ marginBottom: 15 }}
        value={postIt.name || ''}
        onChange={event => onNameChange && onNameChange(event.target.value)}
        maxLength={25}
        placeholder='Write your name here'
        readOnly={readOnly}
      /> : null}
      <textarea
        ref={ref}
        name="answer"
        className={`large ${css(styles.input)}`}
        value={postIt.message}
        onChange={event => onMessageChange && onMessageChange(event.target.value)}
        maxLength={100}
        rows={6}
        placeholder='Write your answer here'
        onKeyPress={(event) => {
          if (event.which === 13) {
            onSubmit && onSubmit()
            event.preventDefault()
          }
        }}
        readOnly={readOnly}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  postIt: {
    height: 310,
    width: 310,
    padding: 30,
  },

  thisPostIt: {
    margin: 48,
  },

  input: {
    backgroundColor: '#0000',
    width: '100%',
  },
});

export default forwardRef(EditablePostIt)
