import { useState } from 'react'
import { sample } from 'lodash'

import { addPostIt } from '../post-its';
import { IPostIt } from '../post-its/types';
import { useCircularNavigation, usePostItStore, useRouter } from './'

const newPostIt = (): IPostIt => ({
  message: '',
  backgroundColor: sample(['#FBC6FF', '#9cbcf6', '#f8EC5C'])!,
})

export default function useAddPostItHandler() {
  const [postIt, setPostIt] = useState<IPostIt>(newPostIt())
  const [postItCount, setPostItCount] = useState(0)
  const [previousPostIt, setPreviousPostIt] = useState<IPostIt | undefined>(undefined)
  const { dispatch } = usePostItStore()
  const circularNavigation = useCircularNavigation()
  const { history } = useRouter()!

  const canSubmitPostIt = postIt.message.length > 5

  const submitPostIt = () => {
    if (!canSubmitPostIt) { return }
    dispatch(addPostIt(postIt))
    if (circularNavigation) {
      history.push('/postits', { useLast: true })
    } else {
      setPreviousPostIt(postIt)
      setPostIt(newPostIt())
      setPostItCount(postItCount+1)
    }
  }

  return {
    previousPostIt,
    postIt,
    setPostItMessage: (message: string) => setPostIt({ ...postIt, message }),
    setPostItName: (name: string) => setPostIt({ ...postIt, name }),
    canSubmitPostIt,
    submitPostIt,
    postItCount
  }
}
