// https://github.com/ReactTraining/react-router/issues/6430

import React, { createContext } from "react";
import { RouteChildrenProps } from "react-router";
import { BrowserRouter, Route } from "react-router-dom";

export const RouterContext = createContext<RouteChildrenProps<any, any> | null>(null);

const Router = ({ children }: React.PropsWithChildren<{}>) => (
  <BrowserRouter>
    <Route>
      {(routeProps) => (
        <RouterContext.Provider value={routeProps}>
          {children}
        </RouterContext.Provider>
      )}
    </Route>
  </BrowserRouter>
);

export default Router;
