import React from 'react'
import { StyleSheet, css } from 'aphrodite'

import Header from './Header'
import PostIt from './PostIt'
import { usePositionedPostItSelection, useCircularNavigation, useRouter } from '../hooks'
import { IPositionedPostIt } from '../hooks/types'

const PostItViewer: React.FC = () => {
  const { location: { state } } = useRouter()!
  const {positionedPostIts, previousPositionedPostIts} = usePositionedPostItSelection((state || { useLast: false }).useLast)
  const circularNavigation = useCircularNavigation()

  if (positionedPostIts === undefined) {
    return null
  }

  const keyCreator = (postIt: IPositionedPostIt) => `${postIt.message}${postIt.backgroundColor}${postIt.position.x}${postIt.position.y}`
  const fadingPostIts = (previousPositionedPostIts || []).map((postIt, i) => <PostIt key={keyCreator(postIt)} {...postIt} fading />)
  const postIts = positionedPostIts.map((postIt, i) => <PostIt key={keyCreator(postIt)} {...postIt}/>)
  return (
    <div className={css(styles.container)}>
      {circularNavigation && <Header link={{ to:'/', text:'add another' }} />}
      <div className={css(styles.content)} style={circularNavigation ? {}:{backgroundColor: '#000'}}>
        <div className={css(styles.mask)}>
          {fadingPostIts}
          {postIts}
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  content: {
    flex: 1,
    position: 'relative',
    height: '100%',
  },
  mask: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
  }
});

export default PostItViewer
