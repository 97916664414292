import * as firebase from 'firebase/app';
import "firebase/firestore";

const ENV = process.env[`REACT_APP_ENV`] as string
const API_KEY = process.env[`REACT_APP_${ENV}_FIREBASE_API_KEY`] as string
const PROJECT_ID = process.env[`REACT_APP_${ENV}_FIREBASE_PROJECT_ID`] as string

const config = {
  apiKey: `${API_KEY}`,
  authDomain: `${PROJECT_ID}.firebaseapp.com`,
  projectId: `${PROJECT_ID}`,
};
firebase.initializeApp(config);

export default firebase.firestore();
